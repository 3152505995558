import { createApp } from 'vue';
import HighchartsVue from 'highcharts-vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';

import './styles/app.scss';

const pinia = createPinia()
const app = createApp(App);

app.use(router)

// eslint-disable-next-line
app.use(HighchartsVue as any)
app.use(pinia)
app.mount('#app');
