<template>
  <div class="mb-20 grid grid-cols-12">
    <div class="box col-span-12 rounded-xl p-5 lg:col-span-8 lg:col-start-3">
      <p class="leading-12 text-lg font-thin text-gray-500 dark:text-gray-300 md:text-3xl">
        <span class="font-medium md:text-4xl">Is er nog gas?</span> ... en hoeveel kost gas? Deze website geeft inzicht in de gas
        voorraad en de gas prijs in Nederland.
        <span class="highlight p-2 font-light text-gray-600 dark:text-white">Elk uur wordt er informatie opgehaald bij verschillende bronnen om een duidelijk inzicht van de
          gasmarkt te geven.</span> Waar de Nederlandse overheid één keer per week een overzicht geeft, weergeeft deze website informatie per uur weer!
      </p>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
p.leading-12 {
  font-family: 'Roboto Slab', serif;
  line-height: 3.5rem;
}

.highlight {
  background-color: #f1f95e8b;
}

.dark .highlight {
  background-color: #161920;
}
</style>
