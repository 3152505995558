import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/gas-prijs',
    name: 'gas-prijs',
    component: () => import(/* webpackChunkName: "gas-price" */ '../views/GasPrice.vue'),
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: () => import(/* webpackChunkName: "disclaimer" */ '../views/DisclaimerView.vue'),
  },
  {
    path: '/woordenlijst',
    name: 'woordenlijst',
    component: () => import(/* webpackChunkName: "wordlist" */ '../views/WordListView.vue'),
  },
  {
    path: '/beta',
    name: 'beta',
    component: () => import(/* webpackChunkName: "beta" */ '../views/BetaView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
    return false;
  },
});

export default router;
