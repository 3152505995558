import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

export interface StorageResponse {
  type?: string;
  city?: string;
  data?: any;
  gas_day?: string;
  name?: string;
  total?: number;
  last_page?: number;
  dataset?: string;
  timestamp?: number;
  id?: string;
}

export const useStorageStore = defineStore('storage', () => {
  const storageData: Ref<StorageResponse[]> = ref([]);

  function addstorageItem(newItem: StorageResponse) {
    const filter = storageData.value.filter((item:StorageResponse) => item.type !== newItem.type);
    storageData.value = [...filter, newItem];
  }

  function getStorageItem(type: string): StorageResponse | undefined {
    return storageData.value.find((storage: StorageResponse) => storage.type === type);
  }

  function hasValidStorageData(type: string): StorageResponse | boolean {
    const item = storageData.value.find((storage: StorageResponse) => storage.type === type);

    if (item && item.timestamp && Date.now() - item.timestamp < 10000) {
      return item;
    }

    return false;
  }

  return { getStorageItem, addstorageItem, hasValidStorageData }
})
