import { useDark } from '@vueuse/core';

export const storageDataIDs: string[] = ['ugs_a', 'ugs_b', 'ugs_g', 'ugs_n', 'ugs_e', 'ugs_u'];

export const colorScheme: { [type: string]: string } = {
  blue: '#A1D9FB',
  orange: '#FACCCB',
  purple: '#CBB9EC',
  green: '#97E890',
  yellow: '#B1D746',
  pink: '#F49AFC'
}

export const DarkColorScheme: { [type: string]: string } = {
  dark: '#20232D',
  light: '#fff'
}

// used for dark / light mode, this way its globally accessible.
export const isDark = useDark();
