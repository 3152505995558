<template>
  <div class="">
    <router-link class="flex items-center" to="/">
      <section class="logo">
        <img class="flame" src="@/assets/flame.png" width="60px" alt="">
        <img class="inner-flame" src="@/assets/inner-flame.png" alt="">
        <img class="sparks" src="@/assets/sparks.png" alt="">
      </section>
      <div class="logo-brand ml-4 text-2xl font-medium uppercase text-purple">
        Is Er Nog <strong>Gas?</strong>
      </div>
    </router-link>
  </div>
</template>
<style lang="scss">
.logo {
  position: relative;
  height: 51px;
  width: 40px;

  >img {
    position: absolute;
  }

  .flame {
    z-index: 1;
    width: 40px;
    height: 51px;
    transform-origin: 50% 50%;

    animation: flame 5s ease infinite;
    backface-visibility: hidden;
    perspective: 1000px;
  }

  .inner-flame {
    z-index: 2;
    bottom: 6px;
    left: 25%;
    width: 20px;
    height: 24px;

    animation: inner-flame 3s linear infinite;
    backface-visibility: hidden;
    perspective: 1000px;
  }
}

.sparks {
  z-index: 3;
  animation: sparks 1.5s linear infinite;
  backface-visibility: hidden;
  perspective: 1000px;
}

// animations
@keyframes sparks {
  0% {
    opacity: 0;
    transform: translate(0, -5px) scale(1)
  }

  40% {
    opacity: 1;
    transform: translate(0, -20px) scale(1.2);
  }

  70% {
    opacity: 0;
    transform: translate(0, -30px) scale(1.2);
  }

  100% {
    opacity: 0;
    transform: translate(0, -5px) scale(1);
  }
}

@keyframes flame {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(7deg) scale(1.05);
  }

  75% {
    transform: rotate(-3deg);
  }
}

@keyframes inner-flame {
  50% {
    transform: rotate(10deg) scaleY(1.2) scaleX(1.2) skewX(5deg) translateY(-3px);
  }
}
</style>
